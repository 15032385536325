import { isMobile } from "../../resources/util";

export default {

    name: "GTooltip",
    props: {

        title: { type: String, required: true },
    },
    data() {

        return {

            isPhone: isMobile(),
        }
    },
    render() {

        return (

            <div>

                {this.isPhone ? this.$slots.default : (

                    <a-tooltip title={this.$props.title}>

                        {this.$slots.default}

                    </a-tooltip>
                )}
            </div>
        )
    }
}