import './index.less';
import GTable from "@/components/table";
import http from "@/http";
import moment from "moment";
import DrawerTimeLine from '../drawer';
import ModalStatus from '@/modules/vistoria/common/ModalActions';
import ModalDetalhes from "@/modules/vistoria/common/ModalDetails";
import ModalSolicitacao from "@/modules/vistoria/common/ModalSolicitacao";
import GTooltip from '@/components/tooltip';
import {isMobile} from "@/resources/util";
import {listaStatus, listaTipoVistoria} from '../../util';

const columns = [
    {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: '100px',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
        scopedSlots: {customRender: 'id'}
    },
    {
        title: 'Endereço',
        dataIndex: 'imovel.logradouro',
        // width: '40%',
        scopedSlots: {customRender: 'endereco'},
        filters: [
            {
                text: 'Vistoria de aditivo',
                value: 'A'
            },
            {
                text: 'Vistoria de conferência',
                value: 'C'
            },
            {
                text: 'Vistoria de entrada',
                value: 'E'
            },
            {
                text: 'Vistoria de saída',
                value: 'S'
            },
            {
                text: 'Vistoria de venda',
                value: 'V'
            },
            {
                text: 'Vistoria de conformidade',
                value: 'CF'
            }
        ],
        filterMultiple: true,
        onFilter: (value, record) => {

            if (record.tipo === value) return record;
        }
    },
    {
        title: 'Vistoriador',
        dataIndex: 'vistoriador.nome',
        scopedSlots: {customRender: 'vistoriador'}
    },
    {
        title: 'Última modificação',
        // sorter: (a, b) => new Date(a.status[0].data) - new Date(b.status[0].data),
        width: '12%',
        scopedSlots: {customRender: 'ultimaModificacao'},
    },
    {
        title: 'Status',
        dataIndex: 'status',
        // colSpan: 2,
        width: '12%',
        align: 'left',
        scopedSlots: {customRender: 'status'},
        filters: [
            {
                text: 'Solicitada',
                value: 'S'
            },
            {
                text: 'Agendado',
                value: 'A'
            },
            {
                text: 'Agendamento confirmado',
                value: 'AC'
            },
            {
                text: 'Iniciado',
                value: 'I'
            },
            {
                text: 'Cancelado',
                value: 'C'
            },
            {
                text: 'Concluído',
                value: 'CL'
            },
            {
                text: 'Liberado para assinatura',
                value: 'PA'
            },
            {
                text: 'Gerando laudo',
                value: 'GL'
            },
            {
                text: 'Laudo gerado',
                value: 'L'
            },
            {
                text: 'Finalizado',
                value: 'F'
            },
            {
                text: 'Contestado',
                value: 'CT'
            }
        ],
        filterMultiple: true,
        onFilter: (value, record) => {

            const filtered = record.status.filter(item => !['AS', 'CT'].includes(item.status));
            if (value === record.status[0].status || value === filtered[0].status) return record;
        }
    },
    {
        // colSpan: 0,
        scopedSlots: {customRender: 'menu'},
    }
];

export default {

    data() {

        return {

            statusData: null,
            visibleInfo: false,
            visibleModal: false,
            visibleModalCT: false,
            visibleSolicitacaoModal: false,
            detailContest: {

                visible: false,
                status_id: undefined,
            },
            drawer: {

                visible: false,
                data: null,
            },
            list: null,
            statisticData: {},
            loading: null,
            pagination: {

                showSizeChanger: true, current: 1,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} itens`,
                onChange: (page) => this.pagination.current = page
            }
        }
    },
    beforeMount() {

        if (!this.session.user.cliente) {

            this.$router.push({name: "main"});
        }
    },
    created() {

        this.loading = true;
        this.load();
    },
    computed: {

        session() {

            return this.$store.getters['session/user'];
        }
    },
    methods: {

        load() {

            this.loading = true;

            const r1 = http.get('painel/vistorias.listarPorCliente').then(({data}) => {

                this.list = data.items;

            }).finally(() => this.loading = false).catch(err => {

                this.$error({
                    title: 'Desculpe, houve um erro!',
                    content: 'Tente novamente mais tarde.',
                });
            });

            const r2 = http.get('painel/dashboard.obterEstatisticasCliente').then((result) => {

                this.statisticData = result.data;
            });

            Promise.all([r1, r2]).finally(() => {

                this.loading = false;
            });

        },
        handleModal(vistoria, status) {

            this.statusData = {

                vistoria,
                status
            };

            this.visibleModal = !this.visibleModal;
        },
        handleSubmitNewStatus(data) {

            if (!data) return;

            this.$message.success('Dados salvos.');

            this.load();
        },
        openLaudo(vistoria, {previa = false} = {}) {

            this.loading = true;

            let laudo = {vistoria_id: vistoria.id};
            if (previa) laudo.previa = 1;

            http.get('/painel/vistorias.laudos.obter', {params: laudo}).then(data => {

                this.windowOpen(data.data.arquivo);

            }).catch(err => {

                this.$error({

                    title: 'Desculpe, houve um erro ao recuperar o laudo.',
                });

            }).finally(() => this.loading = false);
        },
        windowOpen(url) {

            if (!window.open(url, '_blank')) {

                this.$warning({
                    title: 'Ops, o navegador está bloqueando popups.',
                    content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p></div>
                })
            }
        },
        openGallery(record) {

            setTimeout(() => this.$router.push({name: 'galeria', params: {id: record.id}, query: {back: 'vistorias'}}), 1);
        },
        downloadFotos(id) {

            if (!id) return;

            this.loading = true;

            http.get('/painel/vistorias.downloads', {params: {id: id}, responseType: 'blob'}).then(data => {

                const url = window.URL.createObjectURL(new Blob([data], {type: 'application/zip'}));
                this.windowOpen(url);

            }).finally(() => this.loading = false).catch(err => {

                const reader = new FileReader;
                reader.onload = () => {

                    const error = JSON.parse(reader.result);
                    this.$error({
                        title: 'Desculpe, houve um erro ao recuperar o laudo.',
                        content: error.message
                    });
                }
                reader.readAsText(err);
            });
        },
        todosAssinaram(record) {

            const assinam = record.vistoria_ator ? record.vistoria_ator.filter(item => item.assina) : [];
            return record.vistoria_ator && (record.vistoria_ator.filter(item => !!item.assinou_em)).length === assinam.length;
        },
        handleDrawer(vistoria) {

            if (!vistoria) return;

            const {status} = vistoria;

            Object.assign(this.drawer, {

                visible: true,
                data: {

                    vistoria_id: vistoria.id,
                    status
                }
            });
        },
        handleDetailsContest(vistoria, ator) {

            if (!vistoria) return;

            const statusCT = vistoria.status.find(item => item.status === 'CT');

            Object.assign(this.detailContest, {

                visible: true,
                data: {

                    status_id: statusCT.id,
                    ator_id: ator.id
                }
            });
        }
    },
    render() {

        const phone = isMobile();

        const table = {

            scopedSlots: {

                id: (text) => <strong>{text}</strong>,
                endereco: (text, record) => {

                    let endereco = 'Imóvel não informado';

                    if(record.imovel && record.imovel.id){

                        endereco = `${record.imovel.logradouro}, nº ${record.imovel.numero}`;
                        if(record.imovel.bairro) endereco += ` - ${record.imovel.bairro}`;
                        if(record.imovel.cidade.nome) endereco += `, ${record.imovel.cidade.nome} - ${record.imovel.cidade.estado.uf}`;

                    } else if(record.descricao){

                        endereco = record.descricao;
                    }

                    const tipo = listaTipoVistoria[record.tipo];

                    return (
                        <div class='list-endereco-wrapper'>
                            <div>
                                <g-icon icon={['far', 'map-pin']} fixed-width/>
                                <span>{endereco}</span><br/>
                                <g-icon icon={['far', 'sign']} fixed-width/>
                                {record.cliente && record.cliente.id ? (record.cliente.pessoa === 'J' ? (record.cliente.nome_fantasia ||
                                    record.cliente.razao_social) : record.cliente.nome) : 'Cliente não informado'}
                                {record.financeiro && record.financeiro.id
                                    ? (<span> - <g-icon icon={['far', 'dollar-sign']} fixed-width/>Cobrado</span>)
                                    : ''}
                            </div>
                            <div>
                                <a-tag color={tipo.color} class='tag-tipo'>
                                    Vistoria de {tipo.label}
                                </a-tag>
                            </div>
                        </div>
                    );
                },
                vistoriador: (text, record) => {

                    return (
                        <div>
                            <g-icon icon={['far', 'id-badge']} fixed-width/>
                            {text || 'Não informado'}<br/>
                            <g-icon icon={['far', 'university']} fixed-width/>
                            {record.organizacao.nome_fantasia}
                        </div>
                    );
                },
                ultimaModificacao: (text, record) => {

                    return (
                        <a-tooltip
                            title={!record.status.length ? 'Não informado.' : `${moment(record.status[0].data || record.status[0].c_em).format('DD/MM/YYYY HH:mm')}`}
                            placement="bottom">
                            <span><g-icon icon={['far', 'clock']} fixed-width/>
                                {!record.status.length ? 'Não informado.' : `${moment(record.status[0].data || record.status[0].c_em).fromNow()}`}</span>
                        </a-tooltip>
                    );
                },
                status: (text, record) => {

                    if (!record.status || !record.status.length) return undefined;

                    const filtered = record.status.filter(item => !['AS'].includes(item.status));
                    const status = listaStatus[filtered[0].status];

                    const atorStatus = item => {

                        return {
                            AS: {
                                color: 'green',
                                icon: ['fas', 'file-signature'],
                                text: (<span
                                    style="color: green; font-size: 10px;">Assinou em <i>{moment(item.assinou_em).format('DD/MM/YYYY HH:mm')}</i></span>)
                            },
                            CT: {
                                color: 'red',
                                icon: ['fas', 'thumbs-down'],
                                text: (
                                    <span style="display:flex; flex-direction: column;">
                                        <span
                                            style="color: red; font-size: 10px;">Contestou em <i>{moment(item.contestacao.contestado_em).format('DD/MM/YYYY HH:mm')}</i></span>
                                        <span
                                            style="font-size: 10px; color: rgba(0,0,0,.85); display: inline-block; cursor: pointer;"
                                            onmouseover={(e) => e.target.style.color = '#1890ff'}
                                            onmouseout={(e) => e.target.style.color = 'rgba(0,0,0,.85'}
                                            onClick={() => this.handleDetailsContest(record, item)}
                                        >
                                            <g-icon icon={['fas', 'search']} fixedWidth/> Ver detalhes
                                        </span>
                                    </span>
                                )
                            }
                        }
                    };

                    return (

                        <span>
                            <a-badge status={status.status} text={status.label}/>
                            {

                                ['AS', 'CT', 'GL', 'L', 'F'].includes(record.status[0].status) && record.vistoria_ator ? (

                                    <a-popover title="Revisões das partes" placement="leftTop">
                                        <template slot="content">
                                            <div style="width: 200px;">
                                                <a-timeline>
                                                    {

                                                        record.vistoria_ator.filter(item => item.assina).map(item => {

                                                            item.status = (item.contestacao && item.contestacao.id) ? 'CT' : (item.assinou_em ? 'AS' : null);

                                                            return (
                                                                <a-timeline-item key={item.id}
                                                                                 color={item.status ? atorStatus(item)[item.status]['color'] : 'blue'}>
                                                                    <g-icon-layers slot="dot" class="fa-lg">
                                                                        <g-icon icon={['far', 'circle']}/>
                                                                        {
                                                                            item.status ?
                                                                                <g-icon
                                                                                    icon={atorStatus(item)[item.status]['icon']}
                                                                                    transform="shrink-9"/> :
                                                                                <g-icon icon={['fas', 'question']}
                                                                                        transform="shrink-9"/>
                                                                        }
                                                                    </g-icon-layers>
                                                                    <b>{item.ator.nome}</b><br/>
                                                                    {item.status ? atorStatus(item)[item.status]['text'] :
                                                                        <i style="font-size: 10px;">Pendente</i>}
                                                                </a-timeline-item>
                                                            )
                                                        })
                                                    }
                                                </a-timeline>
                                            </div>
                                        </template>
                                        <span
                                            style="font-size: 16px; margin-left: 10px; margin-top: 4px; cursor: pointer;">
                                            <g-icon icon={['far', 'eye']}/>
                                        </span>
                                    </a-popover>

                                ) : undefined
                            }
                        </span>
                    )
                },
                menu: (text, record) => {

                    return (
                        <div style='font-size: 18px; display: flex'>

                            <GTooltip
                                title="Visualizar prévia"
                                placement="top"
                                v-show={~['CL', 'PA', 'AS'].indexOf(record.status[0].status) && !this.todosAssinaram(record)}
                            >
                                <a-button type="link" style="color: #7265e6;"
                                          onClick={() => this.openLaudo(record, {previa: true})}>
                                    <g-icon icon={['fas', 'file-pdf']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            <GTooltip
                                title="Liberar para assinatura"
                                placement="top"
                                v-show={record.status[0].status === "CL"}
                            >
                                <a-button type="link" style="color: #87d068;"
                                          onClick={() => this.handleModal(record, 'PA')}>
                                    <g-icon icon={['fas', 'file-signature']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            <GTooltip
                                placement="top"
                                title="Gerar laudo sem assinatura digital"
                                v-show={record.status[0].status === "CL"}
                            >
                                <a-button type="link" style="color: #FF6200;"
                                          onClick={() => this.handleModal(record, 'GL')}>
                                    <g-icon icon={['fas', 'file-contract']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            <GTooltip
                                title="Visualizar laudo"
                                placement="top"
                                v-show={record.status.find(i => i.status === 'L')}
                            >
                                <a-button type="link" style="color: #7265e6;" onClick={() => this.openLaudo(record)}>
                                    <g-icon icon={['fas', 'file-pdf']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            <GTooltip title="Visualizar detalhes" placement="top">
                                <a-button type="link" onClick={() => this.handleDrawer(record)}>
                                    <g-icon icon={['fas', 'eye']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            <GTooltip
                                title="Visualizar galeria"
                                placement="top"
                                v-show={record.status[0].status !== "S"}
                            >
                                <a-button type="link" onClick={() => this.openGallery(record)}>
                                    <g-icon icon={['fas', 'photo-video']} fixedWidth/>
                                </a-button>
                            </GTooltip>

                            {/*<GTooltip*/}
                            {/*    title="Download das fotos"*/}
                            {/*    placement="top"*/}
                            {/*    v-show={record.status.find(i => i.status === 'L')}*/}
                            {/*>*/}
                            {/*    <a-button type="link" onClick={() => this.downloadFotos(record.id)}>*/}
                            {/*        <g-icon icon={['fas', 'download']} fixedWidth />*/}
                            {/*    </a-button>*/}
                            {/*</GTooltip>*/}
                        </div>
                    )
                }
            },

            props: {
                columns,
                rowKey: 'id',
                dataSource: this.list,
                pagination: this.pagination,
                loading: this.loading,
                scroll: {x: 'max-content'},
            }
        };

        const statisticColProps = {

            props: {

                sm: 24,
                md: 8
            }
        }

        return (
            <div>
                <div style='margin-bottom: 16px;'>
                    <a-row gutter={phone ? [0, 8] : [16, 16]}>
                        <a-col {...statisticColProps}>
                            <a-card loading={this.loading}
                                    bordered={false}
                                    className="card-estatisticas">
                                <a-card-meta title="Avaliação média">
                                    <g-icon slot="avatar"
                                            icon={['fad', 'star-half']}
                                            className="card-icon"/>
                                    <template slot="description">
                                        <a-statistic
                                            value={this.statisticData.mediaAvaliacao || 0}
                                            precision={1}
                                            decimalSeparator=","
                                        >
                                            <template slot="suffix">
                                                <a-rate
                                                    value={this.statisticData.mediaAvaliacao ? parseFloat((Math.round(this.statisticData.mediaAvaliacao * 2) / 2).toFixed(1)) : 0}
                                                    allowHalf
                                                    disabled
                                                    style="margin-left: 16px;"
                                                />
                                            </template>
                                        </a-statistic>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                        <a-col {...statisticColProps}>
                            <a-card loading={this.loading}
                                    bordered={false}
                                    className="card-estatisticas">
                                <a-card-meta title="Metragem vistoriada">
                                    <g-icon slot="avatar"
                                            icon={['fad', 'ruler-triangle']}
                                            className="card-icon"/>
                                    <template slot="description">
                                        <a-statistic
                                            value={this.statisticData.totalAreaImovel || 0}
                                            precision={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            suffix="m²"
                                        />
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                        <a-col {...statisticColProps}>
                            <a-card loading={this.loading}
                                    bordered={false}
                                    className="card-estatisticas"
                                    onClick={() => this.$router.push('/vistorias')}>
                                <a-row>
                                    <a-col sm={8}>
                                        <a-statistic
                                            value={this.statisticData.totalvistoriasAndamento || 0}
                                            title="Em andamento"
                                            suffix="vistoria(s)"
                                        />
                                    </a-col>
                                    <a-col sm={8}>
                                        <a-statistic
                                            value={this.statisticData.totalvistoriasConcluidas || 0}
                                            title={this.statisticData.totalvistoriasConcluidas > 1 ? 'Concluídas' : 'Concluída'}
                                            suffix="vistoria(s)"
                                        />
                                    </a-col>
                                    <a-col sm={8}>
                                        <a-statistic
                                            value={this.statisticData.totalvistoriasFinalizadas || 0}
                                            title={this.statisticData.totalvistoriasFinalizadas > 1 ? 'Finalizadas' : 'Finalizada'}
                                            suffix="vistoria(s)"
                                        />
                                    </a-col>
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
                <div class="view view-body-scrollable">
                    <div class="view-header">
                        <div class="view-header-body" style='padding: 16px 12px'>
                            <div class="view-header-start">

                                <g-icon icon={['fas', 'clipboard-list']}/>
                                <h1 style='font-size: 26px'>Listagem de Vistorias</h1>
                                {
                                    !phone ? (
                                        <a-popover title="Informações" placement="rightTop">
                                            <template slot="content">
                                                <div style="max-width: 400px;">
                                                    <p>Acompanhe por aqui o andamento da(s) vistoria(s) dos seus
                                                        imóveis.</p>
                                                    <p><b>Status:</b> é o andamento atual da vistoria;</p>
                                                    <p><b>Opções:</b> são os ícones de ação exibidos no final da linha
                                                        que dão acesso
                                                        às opções disponíveis para cada vistoria, descritas abaixo:</p>
                                                    <p><b>Prévia do laudo:</b> antes de assinar, veja todas as
                                                        informações do laudo
                                                        e revise cada item;</p>
                                                    <p><b>Liberar para assinatura:</b> assim que a vistoria é finalizada
                                                        pelo
                                                        vistoriador, essa opção fica ativa, clique para informar quando
                                                        o documento
                                                        está liberado para ser assinado pelas partes, que serão avisadas
                                                        imediatamente;</p>
                                                    <p><b>Revisão das partes:</b> quando o laudo precisar ser assinado
                                                        por mais de
                                                        uma pessoa, vá até o ícone <g-icon icon={['far', 'eye']}/> para
                                                        ver quem
                                                        já assinou e quem ainda está pendente. O laudo final será gerado
                                                        quando a
                                                        última pessoa assinar.
                                                    </p>
                                                    <p><b>Visualizar laudo:</b> após concluído o processo, é gerado o
                                                        laudo final
                                                        assinado e definitivo, e você pode acessá-lo nesta opção.</p>
                                                    <p><b>Atendimento:</b> na visualização da linha do tempo procure
                                                        pelo link
                                                        <b>
                                                            <g-icon icon={['fas', 'phone-rotary']}/>
                                                            Atendimento</b>
                                                        para iniciar um atendimento entre você, as partes e o
                                                        vistoriador. Procure
                                                        pelo link de atendimento com o ícone
                                                        <g-icon icon={['fas', 'phone-volume']} fixed-width/> para
                                                        identificar um
                                                        atendimento iniciado.
                                                    </p>
                                                </div>
                                            </template>
                                            <span style="margin-left: 10px; cursor: pointer;">
                                            <g-icon icon={['far', 'question-circle']}/>
                                        </span>
                                        </a-popover>
                                    ) : (
                                        <span
                                            style="margin-left: 10px; cursor: pointer;"
                                            onClick={() => this.visibleInfo = !this.visibleInfo}
                                        >
                                        <g-icon icon={['far', 'question-circle']} style={{fontSize: '20px'}}/>
                                    </span>
                                    )
                                }
                            </div>
                            <div class="view-header-end">
                                <a-button type="primary" onClick={() => this.visibleSolicitacaoModal = true}>
                                    <g-icon icon={['fas', 'plus']} class='fa-fixed'/>
                                    Solicitar uma nova vistoria
                                </a-button>
                            </div>
                        </div>
                    </div>

                    <a-modal
                        footer={null}
                        title='Informações'
                        visible={this.visibleInfo}
                        bodyStyle={{maxHeight: '70vh'}}
                        onCancel={() => this.visibleInfo = !this.visibleInfo}
                    >
                        <p>Acompanhe por aqui o andamento da(s) vistoria(s) dos imóveis.</p>
                        <p><b>Status:</b> é o andamento atual da vistoria;</p>
                        <p><b>Opções:</b> nas vistorias concluídas, prontas para assinar e finalizadas,
                            clique no botão de menu ao fim da linha (
                            <g-icon icon={['fas', 'ellipsis-v']}/>
                            ),
                            ou mantenha pressionado no celular, para ter acesso ao menu de opções;
                        </p>
                        <p><b>Prévia do laudo:</b> antes de assinar, veja todas as informações do laudo
                            e revise cada item;</p>
                        <p><b>Liberar para assinatura:</b> assim que a vistoria é finalizada pelo
                            vistoriador, essa opção fica ativa, clique para informar quando o documento
                            está liberado para ser assinado pelas partes, que serão avisadas
                            imediatamente;</p>
                        <p><b>Revisão das partes:</b> quando o laudo precisar ser assinado por mais de
                            uma pessoa, vá até o ícone <g-icon icon={['far', 'eye']}/> para ver quem
                            já assinou e quem ainda está pendente. O laudo final será gerado quando a
                            última pessoa assinar.
                        </p>
                        <p><b>Visualizar laudo:</b> após concluído o processo, é gerado o laudo final
                            assinado e definitivo, e você pode acessá-lo nesta opção.</p>
                        <p><b>Atendimento:</b> na visualização da linha do tempo procure pelo link
                            <b>
                                <g-icon icon={['fas', 'phone-rotary']}/>
                                Atendimento</b>
                            para iniciar um atendimento entre você, as partes e o vistoriador. Procure
                            pelo link de atendimento com o ícone
                            <g-icon icon={['fas', 'phone-volume']} fixed-width/> para identificar um
                            atendimento iniciado.
                        </p>
                    </a-modal>

                    <ModalStatus
                        visible={this.visibleModal}
                        data={this.statusData}
                        onVisibleChange={(value) => this.visibleModal = value}
                        onSubmit={this.handleSubmitNewStatus}
                    />

                    <ModalSolicitacao
                        visible={this.visibleSolicitacaoModal}
                        onVisibleChange={(visible) => this.visibleSolicitacaoModal = visible}
                        onSubmit={() => this.load()}
                    />

                    <ModalDetalhes
                        visible={this.detailContest.visible}
                        data={this.detailContest.data}
                        onVisibleChange={(visible) => this.detailContest.visible = visible}
                    />

                    <DrawerTimeLine
                        visible={this.drawer.visible}
                        data={this.drawer.data}
                        onVisibleChange={(visible) => this.drawer.visible = visible}
                    />

                    <GTable
                        {...table}
                        onEditRow={(rowId, record) => this.handleDrawer(record)}
                    />
                </div>
            </div>
        )
    }
}
