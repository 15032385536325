import {listaStatus} from "@/modules/vistoria/util";
import hasProp from "ant-design-vue/lib/_util/props-util";
import EventMixin from "@/mixins/EventMixin";
import http from "@/http";
import {isArray} from "@/resources/util";

export default {

	mixins: [EventMixin],
	props: {

		visible: { Type: Boolean },
		// data: { Type: Object, required: true },
	},
	created() {

		let someVisible;

		this.$watch(() => this.visible, is => {

			if (is && !someVisible) {

				someVisible = true;

				this.$nextTick(() => {

					this.$onEvent(this.$refs.modal.$vnode.elm.childNodes[1].firstChild, 'animationend', () => {

						if (!this.visible) {

							this.resetForm();
						}
					})
				})
			}

			this.isVisible = is;
		});

		this.$watch(() => this.data, (value, old) => {

			if (!value) return;

			const { status, vistoria } = value;

			this.form.vistoria_id = vistoria.id;

			this.status = listaStatus[status];
			this.status.sigla = status;

			this.rules.observacao.required = status === 'CT';
		});

	},
	computed: {

		session() {

			return this.$store.getters['session/user'];
		}
	},
	data() {

		return {

			loading: false,
			isVisible: hasProp(this, 'visible') ? this.visible : false,
			rules: {

				tipo: [{

					required: true,
					message: 'Por favor, selecione o tipo da vistoria',
					type: 'string'
				}],
				observacao: {
					min: 3,
					required: false,
					message: 'Observação precisa ser maior ou igual à 3 caracteres.'
				}
			},
			form: {

				tipo: undefined,
				observacao: undefined
			},
			radioStyle: {

				display: 'block',
				height: '30px',
				lineHeight: '30px'
			}
		}
	},
	methods: {

		handleVisibleChange(is) {

			this.$emit('visibleChange', is);
		},
		handleClose() {

			this.handleVisibleChange(false);
			this.$nextTick(() => {

				this.resetForm();
			});
			this.loading = false;
		},
		resetForm() {

			this.$refs.form.clearValidate();

			Object.assign(this.form, {
				tipo: undefined,
				observacao: undefined
			});
		},
		handleSubmit(e) {

			e.preventDefault();
			this.$refs.form.validate(valid => {

				if (valid) {

					this.loading = true;
					this.form.user_id = this.session.user.id;
					this.form.instance_id = this.session.user.cliente.instance_id;
					this.form.cliente_id = this.session.user.cliente.id;

					http.post('/painel/vistorias.salvar', { data: this.form }).then(({ data }) => {

						if (data.id) {

							this.$emit('submit', Object.assign({}, this.form, data));
							this.handleClose();
							this.$success({

								title: 'Atenção',
								content: 'Vistoria solicitada com sucesso, aguarde a confirmação.',
							});
						}
					}).catch(err => {

						this.$error({
							title: err.error && isArray(err.error) ? err.message : 'Atenção, houve um erro.',
							content: (
								err.error && isArray(err.error) ? (
									<ul>
										{err.error.map(e => {
											return e.errors && isArray(e.errors) ? (
												<div>
													{e.errors.map(ee => {
														return <li><div domPropsInnerHTML={ee} /></li>
													})}
												</div>
											) : <li><div domPropsInnerHTML={e} /></li>
										})}
									</ul>
								) : <span>{err.message}</span>
							)
						});

					}).finally(() => {

						this.loading = false;
					});
				}
			});
		}
	},
	render() {

		const formProps = {

			ref: "form",
			attrs: {
				id: 'form-solicitar-vistoria'
			},
			props: {
				model: this.form,
				rules: this.rules
			},
			on: {
				submit: this.handleSubmit
			}
		};

		return (
			<div>
				<a-modal
					ref="modal"
					width={420}
					visible={this.isVisible}
					onCancel={this.handleClose}
					onOk={this.handleSubmit}
					okText='Enviar'
					maskClosable={false}
					keyboard={false}
					closable={!this.loading}
					okButtonProps={{ props: { disabled: this.loading } }}
					cancelButtonProps={{ props: { disabled: this.loading } }}
				>
					<div slot="title">
						<g-icon icon={['fas', 'clipboard-list']} /> Solicitar vistoria
					</div>
					<a-spin spinning={this.loading} tip="Aguarde...">
						<a-form-model {...formProps}>
							<a-row>
								<a-col>
									Solicitando vistoria para:<br/>
									{this.session.user.cliente && this.session.user.cliente.organization && <div>
										<g-icon icon={['far', 'university']} /> <b>{this.session.user.cliente.organization.type === 'J' ? this.session.user.cliente.organization.fantasy_name : this.session.user.cliente.organization.name}</b>
									</div>}
								</a-col>
							</a-row>
							<a-row>
								<a-col>
									<a-form-model-item
										label="Tipo da vistoria"
										prop="tipo"
									>
										<a-radio-group
											buttonStyle="solid"
											v-model={this.form.tipo}
										>
											<a-radio style={this.radioStyle} value="E">Entrada</a-radio>
											<a-radio style={this.radioStyle} value="S">Saída</a-radio>
											<a-radio style={this.radioStyle} value="A">Aditivo</a-radio>
											<a-radio style={this.radioStyle} value="C">Conferência</a-radio>
											<a-radio style={this.radioStyle} value="V">Venda</a-radio>
											<a-radio style={this.radioStyle} value="CF">Conformidade</a-radio>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
							</a-row>
							<a-row>
								<a-col>
									<a-form-model-item
										label="Informações da vistoria"
										prop="observacao"
									>
										<a-textarea
											allowClear
											placeholder="Informe a data desejada para realização, o endereço, e qualquer outra informação"
											maxLength={1000}
											autoSize={{ minRows: 5, maxRows: 8 }}
											v-model={this.form.observacao}
										/>
									</a-form-model-item>
								</a-col>
							</a-row>
						</a-form-model>
					</a-spin>
				</a-modal>
			</div>
		);
	}
}
